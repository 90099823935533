<template>
  <NotPermission v-if="!authenticated" />

  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs
      :routes="[{ name: 'Inicio' }, { name: 'Producto' }, { name: 'Item' }, { name: 'Stock' }]"
    />
    <v-card style="border-radius:10px 10px 0 0;">
      <v-toolbar card style="border-radius:10px 10px 0 0;" color="primary" dark>
        <v-toolbar-title> {{ nombreProducto }} - {{ nombre }} </v-toolbar-title>
      </v-toolbar>
      <v-bottom-nav :active.sync="activeBtn" color="transparent" :value="true">
        <v-btn :to="{ name: 'EditItem', params: { id: $route.params.id } }" flat color="rgba(0,0,0,0.54)">
          <span>Informacion</span>
          <v-icon>assignment</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ListaPrecios', params: { id: $route.params.id } }" flat>
          <span>Precios</span>
          <v-icon>description</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ItemDimension', params: { id: $route.params.id } }" flat>
          <span>Dimension</span>
          <v-icon>description</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'stockItem', params: { id: $route.params.id } }" flat color="teal">
          <span>Stock</span>
          <v-icon>description</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ListItemImagen', params: { id: $route.params.id } }" flat>
          <span>Imágenes</span>
          <v-icon>description</v-icon>
        </v-btn>
      </v-bottom-nav>
      <br />
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Stock item</v-toolbar-title>
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-data-table
          :headers="[{ text: 'bodega' }, { text: 'Stock' }]"
          :items="stocks"
          :rows-per-page-items="[10, 25, 35, 50]"
          class="elevation-1"
        >
          <tr slot="items" slot-scope="props">
            <td class="px-3">
              {{ props.item.bodega.nombre }}
            </td>
            <td class="px-3">
              {{ props.item.stock }}
            </td>
          </tr>
        </v-data-table>
      </v-container>
      <center>
        <v-btn
            color="error"
            @click="$router.push({ name: 'ListadoProductItems', params: { id: id } })"
          >
            Volver
          </v-btn>
      </center>
    </v-card>
    <ModalDelete />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import axios from "axios";
import { baseUrlAPI } from "@/config/api";
const HOST = baseUrlAPI;

export default {
  metaInfo() {
    return { title: "Stock Item" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    ModalDelete: () => import("@/views/items/ModalDeleteImagen")
  },
  data() {
    return {
      activeBtn: 3,
      lista: [],
      stocks: [],
      nombre: "",
      nombreProducto: "",
      id: ""
    };
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },
  async mounted() {
    await this.getItem({ itemId: this.$route.params.id }).then(response => {
      const itemInfo = response.data.data;
      this.id = itemInfo.producto_id;
      this.nombre = itemInfo.nombre;
    });
    await this.getStocks();
    await this.getProducto({ productoId: this.id }).then(response => {
      this.nombreProducto = response.data.data.nombre;
    });
  },
  methods: {
    ...mapActions({
      getItem: "items/getItem",
      getProducto: "productos/getProducto"
    }),
    async getStocks() {
      try {
        const res = await axios.get(`${HOST}/item-productos/${this.$route.params.id}/stocks`);
        this.stocks = res.data.data;
      } catch (error) {
        this.$message.error("error al listar");
      }
    }
  }
};
</script>
